import { useHttpClient } from "@/core/hooks/useHttpClient";
import { AxiosRequestConfig, type AxiosInstance, AxiosResponse } from "axios";
import { Service } from "typedi";
import CoreResponseDto from "../constant/ICoreResponseDto";
import { utf8_to_b64 } from "../utils/functions";

@Service()
export default class AuthService {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = useHttpClient();
  }

  async login(
    payload: LoginRequestDTO,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<CoreResponseDto<UserData>>> {
    return await this.httpClient.post(
      "Api/Authentication/Signin",
      payload,
      config
    );
  }
  async forgotPassword(
    payload: ForgotPasswordDto,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<CoreResponseDto<string>>> {
    return await this.httpClient.post(
      "Api/Authentication/ForgotPassword",
      payload,
      config
    );
  }
  async resetPassword(
    payload: ResetPasswordDto,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<CoreResponseDto<string>>> {
    return await this.httpClient.put(
      "Api/Authentication/ResetPassword",
      payload,
      config
    );
  }

  async changePassword(
    payload: ChangePasswordDto,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<CoreResponseDto<string>>> {
    return await this.httpClient.put(
      "Api/Authentication/ChangePassword",
      payload,
      config
    );
  }

  async refreshToken(
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<CoreResponseDto<any>>> {
    const refreshToken = localStorage.getItem("refreshToken");

    return await this.httpClient.post(
      "Api/Authentications/RefreshToken",
      { refreshToken },
      config
    );
  }

  async getProfile(
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<CoreResponseDto<UserData>>> {
    return await this.httpClient.get("Api/Authentication/GetProfile", config);
  }

  async logOut(
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<CoreResponseDto<string>>> {
    return await this.httpClient.post("Api/Authentications/Logout", config);
  }

  async getRoles(
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<CoreResponseDto<Role[]>>> {
    return await this.httpClient.get("Api/Authentication/GetRoles", config);
  }
}
