<template>
  <section
    v-bind="$attrs"
    class="flex flex-wrap items-center justify-center gap-5 my-5 lg:flex-nowrap lg:justify-between"
  >
    <div class="flex items-center justify-start gap-2 whitespace-nowrap">
      <label for="selectPage" class="text-sm text-dark600">الصفحة</label>
      <select
        id="selectPage"
        @change="(e) => setPage(e)"
        :value="current_page"
        class="!appearance-none !bg-none text-dark600 border border-[#707070] !px-4 rounded-lg flex justify-center items-center text-center text-sm"
      >
        <option v-for="page in last_page" :value="page" :label="page.toString()">{{ page }}</option>
      </select>
      <p class="text-sm text-dark600">من {{ last_page }} صفحة</p>
    </div>
    <div class="inline-flex gap-2 text-sm font-medium">
      <button
        @click="() => setPage(1)"
        :disabled="current_page === 1"
        class="inline-flex items-center justify-center text-white rounded-lg w-11 h-11 bg-primary"
      >
        <i class="bx bxs-chevrons-right"></i>
      </button>
      <button
        @click="() => setPage(current_page - 1)"
        :disabled="current_page === 1"
        class="inline-flex items-center justify-center text-white rounded-lg w-11 h-11 bg-primary"
      >
        <i class="bx bx-chevron-right"></i>
      </button>
      <template
        v-for="(page, index) in pagination(current_page, last_page)"
        :key="index"
      >
        <button
          type="button"
          :class="
            page === current_page
              ? twFocusClass() && ' bg-primary text-white'
              : 'bg-primary/40  text-dark600 '
          "
          class="inline-flex items-center justify-center rounded-lg w-11 h-11"
          @click="() => setPage(page)"
        >
          {{ page }}
        </button>
      </template>
      <button
        @click="() => setPage(current_page + 1)"
        :disabled="current_page === last_page"
        class="inline-flex items-center justify-center text-white rounded-lg w-11 h-11 bg-primary"
      >
        <i class="bx bx-chevron-left"></i>
      </button>
      <button
        @click="() => setPage(last_page)"
        :disabled="current_page === last_page"
        class="inline-flex items-center justify-center text-white rounded-lg w-11 h-11 bg-primary"
      >
        <i class="bx bx-chevrons-left"></i>
      </button>
    </div>
  </section>
</template>
<script lang="ts" setup>
import { useRouter } from "vue-router";
import twFocusClass from "../utils/twFocusClass";

const router = useRouter();

defineProps<{
  last_page: number;
  current_page: number;
}>();

function setPage(page: number | Event) {
  if (typeof page === "object") {
    page = parseInt((page.target as HTMLInputElement).value);
  }
  const prev_query = router.currentRoute.value.query;
  const query = Object.assign({}, prev_query);
  query["page"] = page.toString();
  router.push({ query });
}

function pagination(currentPage: number, pageCount: number) {
  let delta = 2,
    left = currentPage - delta,
    right = currentPage + delta + 1,
    result = [];

  result = Array.from(
    {
      length: pageCount,
    },
    (v, k) => k + 1
  ).filter((i) => i && i >= left && i < right);

  return result;
}
</script>
