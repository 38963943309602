import { ERoutesName } from "@/core/constant/ERoutesName";
import { RouteRecordRaw, RouterView } from "vue-router";
import { RoleType } from '../../core/constant/EUserRoles';

const routes: RouteRecordRaw = {
  path: "/bonuses/",
  component: RouterView,
  name: ERoutesName.BONUSES,
  redirect: { name: ERoutesName.BONUSES_INDEX },
  meta: {
    allowedRoles: [RoleType.Manager, RoleType.Admin],
    breadcrumb: [
      {
        text: "ادارة المكافآت",
        router_name: ERoutesName.BONUSES,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.BONUSES_INDEX,
      component: () => import("@/pages/bonuses/screens/index.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة المكافآت",
            router_name: ERoutesName.BONUSES,
          },
          {
            text: " المكافآت",
            router_name: ERoutesName.BONUSES_INDEX,
          },
        ],
      },
    },

    {
      path: "create",
      name: ERoutesName.BONUSES_CREATE,
      component: () => import("@/pages/bonuses/screens/create.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة المكافآت",
            router_name: ERoutesName.BONUSES,
          },
          {
            text: " المكافآت",
            router_name: ERoutesName.BONUSES_INDEX,
          },
          {
            text: "تعديل المكافآت",
            router_name: ERoutesName.BONUSES_CREATE,
          },
        ],
      },
    },
  ],
};

export default routes;
