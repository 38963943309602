import type { App } from "vue";
import Container from "./Container.vue";
import NoContent from "./NoContent.vue";
import Pagination from "./Pagination.vue";
import Loading from "./Loading.vue";
import InputField from "./InputField.vue";
import ModuleHeader from "./ModuleHeader.vue";
import ActionsSection from "./ActionsSection.vue";
import Alert from "./Alert.vue";
import Labeled from "./Labeled.vue";
import SelectField from "./SelectField.vue";
import SearchInput from "./SearchInput.vue";
import FileInput from "./FileInput.vue";
import SkeltonLoading from "./SkeltonLoading.vue";
import { QuillEditor } from "@vueup/vue-quill";
import Multiselect from "./Multiselect.vue";
import MultiFileInput from "./MultiFileInput.vue";
import Image from "./Image/Image.vue";
// register globally
function initGlobalComponents(app: App) {
  app.component("Container", Container);
  app.component("NoContent", NoContent);
  app.component("Pagination", Pagination);
  app.component("Loading", Loading);
  app.component("ModuleHeader", ModuleHeader);
  app.component("InputField", InputField);
  app.component("ActionsSection", ActionsSection);
  app.component("Alert", Alert);
  app.component("SelectField", SelectField);
  app.component("Labeled", Labeled);
  app.component("SearchInput", SearchInput);
  app.component("FileInput", FileInput);
  app.component("SkeltonLoading", SkeltonLoading);
  app.component("QuillEditor", QuillEditor);
  app.component("Multiselect", Multiselect);
  app.component("MultiFileInput", MultiFileInput);
  app.component("Image", Image);
}

export default initGlobalComponents;
