import { ERoutesName } from "@/core/constant/ERoutesName";
import { RouteRecordRaw, RouterView } from "vue-router";
import { RoleType } from '../../core/constant/EUserRoles';

const routes: RouteRecordRaw = {
  path: "/brands/",
  component: RouterView,
  name: ERoutesName.BRANDS,
  redirect: { name: ERoutesName.BRANDS_INDEX },
  meta: {
    allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
    breadcrumb: [
      {
        text: "ادارة العلامات التجارية",
        router_name: ERoutesName.BRANDS,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.BRANDS_INDEX,
      component: () => import("@/pages/brands/screens/index.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin , RoleType.User] ,
        breadcrumb: [
          {
            text: "ادارة العلامات التجارية",
            router_name: ERoutesName.BRANDS,
          },
          {
            text: "قائمة العلامات التجارية",
            router_name: ERoutesName.BRANDS_INDEX,
          },
        ],
      },
    },
    {
      path: "show/:id",
      name: ERoutesName.BRANDS_SHOW,
      component: () => import("@/pages/brands/screens/show.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
        breadcrumb: [
          {
            text: "ادارة العلامات التجارية",
            router_name: ERoutesName.BRANDS,
          },
          {
            text: "قائمة العلامات التجارية",
            router_name: ERoutesName.BRANDS_INDEX,
          },
          {
            text: "عرض العلامة التجارية",
            router_name: ERoutesName.BRANDS_SHOW,
          },
        ],
      },
    },
    {
      path: "create",
      name: ERoutesName.BRANDS_CREATE,
      component: () => import("@/pages/brands/screens/create.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
        breadcrumb: [
          {
            text: "ادارة العلامات التجارية ",
            router_name: ERoutesName.BRANDS,
          },
          {
            text: "قائمة العلامات التجارية ",
            router_name: ERoutesName.BRANDS_INDEX,
          },
          {
            text: "اضافة علامة تجارية",
            router_name: ERoutesName.BRANDS_CREATE,
          },
        ],
      },
    },
    {
      path: "edit/:id",
      name: ERoutesName.BRANDS_EDIT,
      component: () => import("@/pages/brands/screens/update.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
        breadcrumb: [
          {
            text: "ادارة العلامات التجارية",
            router_name: ERoutesName.BRANDS,
          },
          {
            text: "قائمة العلامات التجارية",
            router_name: ERoutesName.BRANDS_INDEX,
          },
          {
            text: "تعديل العلامة التجارية",
            router_name: ERoutesName.BRANDS_EDIT,
          },
        ],
      },
    },
  ],
};

export default routes;
