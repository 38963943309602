import { ERoutesName } from "@/core/constant/ERoutesName";
import { RouteRecordRaw, RouterView } from "vue-router";
import { RoleType } from '../../core/constant/EUserRoles';

const routes: RouteRecordRaw = {
  path: "/delivery-options/",
  component: RouterView,
  name: ERoutesName.DELIVRY_OPTIONS,
  redirect: { name: ERoutesName.DELIVRY_OPTIONS_INDEX },
  meta: {
    allowedRoles: [RoleType.Manager, RoleType.Admin],
    breadcrumb: [
      {
        text: "ادارة خيارات التوصيل",
        router_name: ERoutesName.DELIVRY_OPTIONS,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.DELIVRY_OPTIONS_INDEX,
      component: () => import("@/pages/deliveryOptions/screens/index.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة خيارات التوصيل",
            router_name: ERoutesName.DELIVRY_OPTIONS,
          },
          {
            text: "قائمة خيارات التوصيل",
            router_name: ERoutesName.DELIVRY_OPTIONS_INDEX,
          },
        ],
      },
    },
    {
      path: "create",
      name: ERoutesName.DELIVRY_OPTIONS_CREATE,
      component: () => import("@/pages/deliveryOptions/screens/create.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة خيارات التوصيل ",
            router_name: ERoutesName.DELIVRY_OPTIONS,
          },
          {
            text: "قائمة خيارات التوصيل ",
            router_name: ERoutesName.DELIVRY_OPTIONS_INDEX,
          },
          {
            text: "اضافة خيارات التوصيل",
            router_name: ERoutesName.DELIVRY_OPTIONS_CREATE,
          },
        ],
      },
    },
    {
      path: "edit/:id",
      name: ERoutesName.DELIVRY_OPTIONS_EDIT,
      component: () => import("@/pages/deliveryOptions/screens/update.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة خيارات التوصيل",
            router_name: ERoutesName.DELIVRY_OPTIONS,
          },
          {
            text: "قائمة خيارات التوصيل",
            router_name: ERoutesName.DELIVRY_OPTIONS_INDEX,
          },
          {
            text: "تعديل خيارات التوصيل",
            router_name: ERoutesName.DELIVRY_OPTIONS_EDIT,
          },
        ],
      },
    },
  ],
};

export default routes;
