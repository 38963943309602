import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import AuthService from "@/core/services/auth";
import { utf8_to_b64 } from "../utils/functions";
import { getFCMToken } from "../firebase";
import { useToast } from "vue-toastification";

export const useAuth = defineStore("AuthStore", () => {
  const _repo = new AuthService();
  //
  const user = ref<UserData | null>();
  const roles = ref<Role[]>();
  const is_loading = ref(false);
  const toast = useToast();

  const login = async (credentials: LoginRequestDTO) => {
    try {
      is_loading.value = true;
      const fcmToken = await getFCMToken();
      const response = await _repo.login({ ...credentials, fcmToken });
      user.value = response.data.data;
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("refreshToken", response.data.data.refreshToken);
      localStorage.setItem(
        "refreshTokenExpiryTime",
        response.data.data.refreshTokenExpiryTime
      );
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      is_loading.value = false;
    }
  };

  const getProfile = async () => {
    is_loading.value = true;
    try {
      const { data } = await _repo.getProfile();
      user.value = data.data;
      return data;
    } catch (e) {
      return null;
    } finally {
      is_loading.value = false;
    }
  };
  const forgotPassword = async (payload: ForgotPasswordDto) => {
    is_loading.value = true;
    try {
      await _repo.forgotPassword(payload);
      return "تم ارسال بريد الكتروني لاعادة تعيين كلمة المرور"
    } catch (e) {
      throw e;
    } finally {
      is_loading.value = false;
    }
  };

  const logout = async () => {
    localStorage.clear();
    location.href = "/auth/login";
  };

  const getRoles = async () => {
    is_loading.value = true;
    try {
      const { data } = await _repo.getRoles();
      roles.value = data.data;
      return data;
    } catch (e) {
      throw e;
    } finally {
      is_loading.value = false;
    }
  };

  const changePassword = async (payload: ChangePasswordDto) => {
    try {
      is_loading.value = true;
      await _repo.changePassword(payload);
      toast.success("تم تحديث كلمة المرور بنجاح");
    } catch (e) {
      throw e;
    } finally {
      is_loading.value = false;
    }
  };


  const resetPassword = async (payload: ResetPasswordDto) => {
    try {
      is_loading.value = true;
      await _repo.resetPassword(payload);
      toast.success("تم تحديث كلمة المرور بنجاح");
    } catch (e) {
      throw e;
    } finally {
      is_loading.value = false;
    }
  };

  return {
    user,
    login,
    logout,
    getProfile,
    getRoles,
    roles: computed(() => roles.value),
    is_loading,
    resetPassword,
    forgotPassword,
    changePassword
  } as const;
});
