import { ERoutesName } from "@/core/constant/ERoutesName";
import { RouteRecordRaw, RouterView } from "vue-router";
import { RoleType } from '../../core/constant/EUserRoles';

const routes: RouteRecordRaw = {
  path: "/categories/",
  component: RouterView,
  name: ERoutesName.CATEGORIES,
  redirect: { name: ERoutesName.CATEGORIES_INDEX },
  meta: {
    allowedRoles: [RoleType.Manager, RoleType.Admin],
    breadcrumb: [
      {
        text: "ادارة التصنيفات",
        router_name: ERoutesName.CATEGORIES,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.CATEGORIES_INDEX,
      component: () => import("@/pages/categories/screens/index.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة التصنيفات",
            router_name: ERoutesName.CATEGORIES,
          },
          {
            text: "قائمة التصنيفات",
            router_name: ERoutesName.CATEGORIES_INDEX,
          },
        ],
      },
    },
    {
      path: "show/:id",
      name: ERoutesName.CATEGORIES_SHOW,
      component: () => import("@/pages/categories/screens/show.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة التصنيفات",
            router_name: ERoutesName.CATEGORIES,
          },
          {
            text: "قائمة التصنيفات",
            router_name: ERoutesName.CATEGORIES_INDEX,
          },
          {
            text: "عرض التصنيف",
            router_name: ERoutesName.CATEGORIES_SHOW,
          },
        ],
      },
    },
    {
      path: "create",
      name: ERoutesName.CATEGORIES_CREATE,
      component: () => import("@/pages/categories/screens/create.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة التصنيفات",
            router_name: ERoutesName.CATEGORIES,
          },
          {
            text: "قائمة التصنيفات",
            router_name: ERoutesName.CATEGORIES_INDEX,
          },
          {
            text: "اضافة تصنيف",
            router_name: ERoutesName.CATEGORIES_CREATE,
          },
        ],
      },
    },
    {
      path: "edit/:id",
      name: ERoutesName.CATEGORIES_EDIT,
      component: () => import("@/pages/categories/screens/update.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة التصنيفات",
            router_name: ERoutesName.CATEGORIES,
          },
          {
            text: "قائمة التصنيفات",
            router_name: ERoutesName.CATEGORIES_INDEX,
          },
          {
            text: "تعديل تصنيف",
            router_name: ERoutesName.CATEGORIES_EDIT,
          },
        ],
      },
    },
  ],
};

export default routes;
