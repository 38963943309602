import { ERoutesName } from "@/core/constant/ERoutesName";
import { RouteRecordRaw, RouterView } from "vue-router";
import { RoleType } from "../../core/constant/EUserRoles";

const routes: RouteRecordRaw = {
  path: "/customers/",
  component: RouterView,
  name: ERoutesName.CUSTOMER,
  redirect: { name: ERoutesName.CUSTOMER_INDEX },
  meta: {
    allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
    breadcrumb: [
      {
        text: "ادارة العملاء",
        router_name: ERoutesName.CUSTOMER,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.CUSTOMER_INDEX,
      component: () => import("@/pages/customers/screens/index.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
        breadcrumb: [
          {
            text: "ادارة العملاء",
            router_name: ERoutesName.BRANDS,
          },
          {
            text: "قائمة العملاء",
            router_name: ERoutesName.BRANDS_INDEX,
          },
        ],
      },
    },
    {
      path: "show/:id",
      name: ERoutesName.CUSTOMER_SHOW,
      component: () => import("@/pages/customers/screens/show.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
        breadcrumb: [
          {
            text: "ادارة العملاء",
            router_name: ERoutesName.CUSTOMER,
          },
          {
            text: "قائمة العملاء",
            router_name: ERoutesName.CUSTOMER_INDEX,
          },
          {
            text: "عرض العميل",
            router_name: ERoutesName.CUSTOMER_SHOW,
          },
        ],
      },
    },
  ],
};

export default routes;
