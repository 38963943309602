<template>
  <div>
    <label :for="name" class="flex items-center gap-1">
      <span v-if="required" class="text-2xl font-bold text-red-600">*</span>
      <span>
        {{ label }}
      </span>
    </label>
    <Field
      :type="type"
      :name="name"
      :id="name"
      v-model="modelValue"
      :label="label"
      :as="as || 'input'"
      class="input"
      :placeholder="placeholder"
    />
    <ErrorMessage :name="name" class="error-message" />
  </div>
</template>
<script setup lang="ts">
import { Field, ErrorMessage } from "vee-validate";

const modelValue = defineModel<string | number>();

defineProps<{
  name: string;
  required?: boolean;
  label: string;
  type: string;
  as?: string;
  placeholder?: string;
}>();
</script>
