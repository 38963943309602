import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { useAuth } from "../controllers/auth";
import { ERoutesName } from "../constant/ERoutesName";
import useHasRole from "../hooks/useHasRole";
import { RoleType } from "../constant/EUserRoles";

export const navigationGuards = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const auth = useAuth();
  const { has } = useHasRole();

  // if the route is guest only then let the user continue
  if (to.meta.guest) {
    document.getElementById("InitScreenDOM")?.remove();
    return next();
  }

  if (!auth.user) {
    const res = await auth.getProfile();
    document.getElementById("InitScreenDOM")?.remove();

    if (res) {
      // if the user is logged in and he doesn't have permission to access the route then redirect to forbidden
      if (!has(to.meta.allowedRoles as RoleType[]) && !to.meta.guest) {
        return next("/Forbidden");
      }

      // the user is logged in and trying to access the login page then redirect to dashboard
      if (to.meta.guest) {
        return next("/");
      }

      // continue to the route
      return next();
    }

    // if the user is not logged in and the route is not guest only then redirect to login
    if (to.meta.guest) {
      return next();
    }

    return next("/auth/login");
  }

  // if the user is logged in and he doesn't have permission to access the route then redirect to forbidden
  if (!has(to.meta.allowedRoles as RoleType[]) && !to.meta.guest) {
    document.getElementById("InitScreenDOM")?.remove();
    return next("/Forbidden");
  }

  // otherwise continue to the route
  document.getElementById("InitScreenDOM")?.remove();
  next();

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
};
