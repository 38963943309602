import { ERoutesName } from "./ERoutesName";
import { RoleType } from "./EUserRoles";

export const navigationMenu = [
  {
    name: ERoutesName.USERS_INDEX,
    title: "ادارة المستخدمين",
    icon: "bx bxs-user-detail",
    children: [],
    allowedRoles: [RoleType.Manager, RoleType.Admin],
  },
  {
    name: ERoutesName.CITIES_INDEX,
    title: "ادارة المدن",
    icon: "bx bx-map-pin",
    children: [],
    allowedRoles: [RoleType.Manager, RoleType.Admin],
  },
  {
    name: ERoutesName.CUSTOMER_SUPPORT_INDEX,
    title: "ادارة الدعم الفني",
    icon: "bx bx-support",
    children: [],
    allowedRoles: [RoleType.Manager, , RoleType.Admin],
  },
  {
    name: ERoutesName.BANNER,
    title: "ادارة البانرات",
    icon: "bx bx-carousel",
    children: [],
    allowedRoles: [RoleType.Manager, RoleType.Admin],
  },
  {
    name: ERoutesName.BRANDS_INDEX,
    title: "ادارة العلامات التجارية",
    icon: "bx bx-star",
    children: [],
    allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
  },
  {
    name: ERoutesName.VENDORS_INDEX,
    title: "ادارة البائعين",
    icon: "bx bx-user-pin",
    children: [],
    allowedRoles: [RoleType.Manager, RoleType.Admin],
  },
  {
    name: ERoutesName.CUSTOMER_INDEX,
    title: "ادارة العملاء",
    icon: "bx bx-user",
    children: [],
    allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
  },
  {
    name: ERoutesName.DELIVRY_OPTIONS,
    title: "ادارة خيارات التوصيل",
    icon: "bx bxs-shopping-bags",
    children: [],
    allowedRoles: [RoleType.Manager, RoleType.Admin],
  },
  {
    name: ERoutesName.ORDERS,
    title: "ادارة الطلبات",
    icon: "bx bx-package",
    children: [],
    allowedRoles: [
      RoleType.Manager,
      RoleType.User,
      RoleType.Vendor,
      RoleType.Admin,
    ],
  },
  {
    name: ERoutesName.PAYMENT,
    title: "ادارة الدفع",
    icon: "bx bx-money",
    children: [],
    allowedRoles: [RoleType.Manager, RoleType.Admin],
  },
  {
    name: ERoutesName.FILTER_TOOL,
    title: "ادارة ادوات الفلاتر",
    icon: "bx bx-filter-alt",
    children: [],
    allowedRoles: [RoleType.Manager, RoleType.Admin],
  },
  {
    name: ERoutesName.COUPONS,
    title: "ادارة الكوبونات",
    icon: "bx bxs-coupon",
    children: [],
    allowedRoles: [RoleType.Manager, RoleType.Admin],
  },
  {
    name: ERoutesName.WALLET,
    title: "ادارة المحافظ",
    icon: "bx bx-wallet-alt",
    children: [],
    allowedRoles: [RoleType.Manager, RoleType.Admin],
  },
  {
    name: ERoutesName.BONUSES,
    title: "ادارة المكافآت",
    icon: "bx bxs-badge-dollar",
    children: [],
    allowedRoles: [RoleType.Manager, RoleType.Admin],
  },

  {
    name: ERoutesName.CATEGORIES_INDEX,
    title: "ادارة التصنيفات",
    icon: "bx bx-category",
    allowedRoles: [RoleType.Manager, RoleType.Admin],
    children: [
      {
        name: ERoutesName.CATEGORIES_INDEX,
        title: "ادارة التصنيفات",
        icon: "bx bx-category",
        children: [],
        allowedRoles: [RoleType.Manager, RoleType.Admin],
      },
      {
        name: ERoutesName.SUB_CATEGORIES_INDEX,
        title: "ادارة التصنيفات الفرعية",
        icon: "bx bx-category-alt",
        children: [],
        allowedRoles: [RoleType.Manager, RoleType.Admin],
      },
    ],
  },

  {
    name: ERoutesName.CAR_MODEL,
    title: "ادارة السيارات",
    icon: "bx bx-car",
    allowedRoles: [RoleType.Manager, RoleType.Admin],
    children: [
      {
        name: ERoutesName.CAR_MAKER,
        title: "ادارة الشركات المصنعة",
        icon: "bx bx-buildings",
        children: [],
        allowedRoles: [RoleType.Manager, RoleType.Admin],
      },
      {
        name: ERoutesName.CAR_MODEL,
        title: "ادارة الموديلات",
        icon: "bx bx-car",
        children: [],
        allowedRoles: [RoleType.Manager, RoleType.Admin],
      },
      {
        name: ERoutesName.CAR_SUB_MODEL,
        title: "ادارة الموديلات الفرعية",
        icon: "bx bx-car",
        children: [],
        allowedRoles: [RoleType.Manager, RoleType.Admin],
      },
    ],
  },

  {
    name: ERoutesName.PRODUCTS_INDEX,
    title: "ادارة المنتجات",
    icon: "bx bx-box",
    allowedRoles: [
      RoleType.Manager,
      RoleType.Admin,
      RoleType.User,
      RoleType.Vendor,
    ],
    children: [
      {
        name: ERoutesName.PRODUCTS_INDEX,
        title: "ادارة المنتجات",
        icon: "bx bx-box",
        children: [],
        allowedRoles: [
          RoleType.Manager,
          RoleType.Admin,
          RoleType.User,
          RoleType.Vendor,
        ],
      },
      {
        name: ERoutesName.PRODUCTS_VENDOR_PRODUCTS,
        title: "ادارة منتجات المزودين",
        icon: "bx bx-box",
        children: [],
        allowedRoles: [
          RoleType.Manager,
          RoleType.Admin,
          RoleType.User,
          RoleType.Vendor,
        ],
      },
      {
        name: ERoutesName.PRODUCTS_IMPORT,
        title: "استيراد منتجات",
        icon: "bx bx-box",
        children: [],
        allowedRoles: [
          RoleType.Manager,
          RoleType.Admin,
          RoleType.User,
          RoleType.Vendor,
        ],
      },
      {
        name: ERoutesName.SAMPLE_FILE_INDEX,
        title: "قوالب الاستيراد",
        icon: "bx bx-box",
        children: [],
        allowedRoles: [
          RoleType.Manager,
          RoleType.Admin,
          RoleType.User,
          RoleType.Vendor,
        ],
      },
      {
        name: ERoutesName.PRODUCT_TAX,
        title: "ادارة ضريبة المنتجات",
        icon: "bx bx-money",
        children: [],
        allowedRoles: [
          RoleType.Manager,
          RoleType.Admin,
          RoleType.User,
          RoleType.Vendor,
        ],
      },
      {
        name: ERoutesName.PRODUCT_UNIT,
        title: "ادارة وحدة المنتجات",
        icon: "bx bx-box",
        children: [],
        allowedRoles: [
          RoleType.Manager,
          RoleType.Admin,
          RoleType.User,
          RoleType.Vendor,
        ],
      },
    ],
  },

  {
    name: ERoutesName.SETTINGS,
    title: "ادارة الاعدادات",
    icon: "bx bx-cog",
    allowedRoles: [RoleType.Manager, RoleType.Admin],

    children: [
      {
        name: ERoutesName.TERMS,
        title: "ادارة الشروط والاحكام",
        icon: "bx bx-info-circle",
        children: [],
        allowedRoles: [RoleType.Manager, RoleType.Admin],
      },
      {
        name: ERoutesName.APPLICATION_OPTION,
        title: "ادارة خيارات التطبيق",
        icon: "bx bx-devices",
        children: [],
        allowedRoles: [RoleType.Manager, RoleType.Admin],
      },
    ],
  },
  {
    name: ERoutesName.SPLASH_SCREEN,
    title: "ادارة شاشة البداية",
    icon: "bx bx-carousel",
    children: [],
    allowedRoles: [RoleType.Manager, RoleType.Admin],
  },
];
