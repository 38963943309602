<template>
  <div>
    <label :for="name" class="flex items-center gap-1">
      <span v-if="required" class="text-2xl font-bold text-red-600">*</span>
      <span>
        {{ label }}
      </span>
    </label>
    <Field
      :name="name"
      :id="name"
      as="select"
      v-model="modelValue"
      class="input"
    >
      <option value="" disabled hidden>اختر</option>
      <slot />
    </Field>
    <ErrorMessage :name="name" class="error-message" />
  </div>
</template>
<script setup lang="ts">
import { Field, ErrorMessage } from "vee-validate";


const modelValue = defineModel<string | number>();

defineProps<{
  name: string;
  label: string;
  required?: boolean;
}>();
</script>
