import { ERoutesName } from "@/core/constant/ERoutesName";
import { RouteRecordRaw, RouterView } from "vue-router";
import Layout from "./components/Layout.vue";

const routes: RouteRecordRaw = {
  path: "/auth/",
  component: Layout,
  name: ERoutesName.AUTH,
  redirect: { name: ERoutesName.LOGIN },
  meta: {
    guest: true,
  },
  children: [
    {
      path: "login",
      name: ERoutesName.LOGIN,
      component: () => import("@/pages/auth/screens/Login.vue"),
      meta: {
        guest: true,
      },
    },

    {
      path: "forgot-password",
      name: ERoutesName.FORGOT_PASSWORD,
      component: () => import("@/pages/auth/screens/ForgotPassword.vue"),
      meta: {
        guest: true,
      },
    },
    {
      path: "reset-password",
      name: ERoutesName.RESET_PASSWORD,
      component: () => import("@/pages/auth/screens/ResetPassword.vue"),
      meta: {
        guest: true,
      },
    },
    
  ],
};

export default routes;
