export default {
  APP_NAME: "Quadro",
  BASE_URL: "https://www.quadro.ly/", //"http://192.168.1.107:8080/",//,
  API_VERSION: "",
  firebaseConfig: {
    apiKey: "AIzaSyBobx2pTpEC2v_sXW_Ef3C6TbzgxrsjsTc",
    authDomain: "quadro-v2.firebaseapp.com",
    projectId: "quadro-v2",
    storageBucket: "quadro-v2.appspot.com",
    messagingSenderId: "77330207094",
    appId: "1:77330207094:web:d7ceb4c4582ddfeca6ea57",
    measurementId: "G-5BZ323TGDL",
  },
  FCM_vapidKey:
    "BOAMgPbjnljq-5nOBxzV88Z96BHQB_lZ8OFqSmrVXzoHd7n_cz9E8FYYxZAlp-08gTTOGDjj3WLv6q40COn0u5o",
};
