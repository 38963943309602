<template>
  <nav class="px-4 bg-white shadow header">
    <div
      class="flex items-center justify-between px-12 py-3 mx-auto lg:justify-end"
    >
      <button class="lg:hidden" @click="$emit('toggleSidebar')">
        <i class="text-3xl bx bx-menu text-primaryDark"></i>
      </button>

      <DropdownMenu dir="rtl" v-if="auth.user">
        <DropdownMenuTrigger>
          <Avatar>
            <AvatarImage :src="auth.user?.imagePath || ''" alt="quadro" />
            <AvatarFallback
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style="fill: rgba(0, 0, 0, 1)"
              >
                <path
                  d="M12 2A10.13 10.13 0 0 0 2 12a10 10 0 0 0 4 7.92V20h.1a9.7 9.7 0 0 0 11.8 0h.1v-.08A10 10 0 0 0 22 12 10.13 10.13 0 0 0 12 2zM8.07 18.93A3 3 0 0 1 11 16.57h2a3 3 0 0 1 2.93 2.36 7.75 7.75 0 0 1-7.86 0zm9.54-1.29A5 5 0 0 0 13 14.57h-2a5 5 0 0 0-4.61 3.07A8 8 0 0 1 4 12a8.1 8.1 0 0 1 8-8 8.1 8.1 0 0 1 8 8 8 8 0 0 1-2.39 5.64z"
                ></path>
                <path
                  d="M12 6a3.91 3.91 0 0 0-4 4 3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4zm0 6a1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2 1.91 1.91 0 0 1-2 2z"
                ></path></svg
            ></AvatarFallback> </Avatar
        ></DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel>الاعدادات</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            @click="$router.push({ name: ERoutesName.PROFILE })"
            class="flex items-center gap-2"
          >
            <i class="bx bx-user"></i>
            <span> حسابي </span>
          </DropdownMenuItem>

          <DropdownMenuItem
            @click="auth.logout"
            class="flex items-center gap-2"
          >
            <i class="bx bx-exit"></i>
            <span> تسجيل الخروج </span></DropdownMenuItem
          >
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  </nav>
</template>
<script lang="ts" setup>
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useAuth } from "../controllers/auth";
import { clsx } from "clsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ERoutesName } from "../constant/ERoutesName";

const auth = useAuth();
</script>
