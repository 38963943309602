import { ERoutesName } from "@/core/constant/ERoutesName";
import { RouteRecordRaw, RouterView } from "vue-router";
import { RoleType } from "../../core/constant/EUserRoles";

const routes: RouteRecordRaw = {
  path: "/orders/",
  component: RouterView,
  name: ERoutesName.ORDERS,
  redirect: { name: ERoutesName.ORDERS_INDEX },
  meta: {
    allowedRoles: [
      RoleType.Manager,
      RoleType.User,
      RoleType.Vendor,
      RoleType.Admin,
    ],
    breadcrumb: [
      {
        text: "ادارة الطلبات",
        router_name: ERoutesName.ORDERS,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.ORDERS_INDEX,
      component: () => import("@/pages/orders/screens/index.vue"),
      meta: {
        allowedRoles: [
          RoleType.Manager,
          RoleType.User,
          RoleType.Vendor,
          RoleType.Admin,
        ],
        breadcrumb: [
          {
            text: "ادارة الطلبات",
            router_name: ERoutesName.ORDERS,
          },
          {
            text: "قائمة الطلبات",
            router_name: ERoutesName.ORDERS_INDEX,
          },
        ],
      },
    },
    {
      path: "show/:id",
      name: ERoutesName.ORDERS_SHOW,
      component: () => import("@/pages/orders/screens/show.vue"),
      meta: {
        allowedRoles: [
          RoleType.Manager,
          RoleType.User,
          RoleType.Vendor,
          RoleType.Admin,
        ],
        breadcrumb: [
          {
            text: "ادارة الطلبات",
            router_name: ERoutesName.ORDERS,
          },
          {
            text: "قائمة الطلبات",
            router_name: ERoutesName.ORDERS_INDEX,
          },
          {
            text: "عرض  الطلب",
            router_name: ERoutesName.ORDERS_SHOW,
          },
        ],
      },
    },
  ],
};

export default routes;
