import { RouteRecordRaw, RouterView } from "vue-router";
import { ERoutesName } from "../../core/constant/ERoutesName";
import { RoleType } from "../../core/constant/EUserRoles";

const routes: RouteRecordRaw = {
  path: "/product/",
  component: RouterView,
  name: ERoutesName.PRODUCTS,
  redirect: { name: ERoutesName.PRODUCTS_INDEX },
  meta: {
    allowedRoles: [
      RoleType.Manager,
      RoleType.Admin,
      RoleType.User,
      RoleType.Vendor,
    ],
    breadcrumb: [
      {
        text: "ادارة المنتجات",
        router_name: ERoutesName.PRODUCTS,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.PRODUCTS_INDEX,
      component: () => import("@/pages/products/screens/index.vue"),
      meta: {
        allowedRoles: [
          RoleType.Manager,
          RoleType.Admin,
          RoleType.User,
          RoleType.Vendor,
        ],
        breadcrumb: [
          {
            text: "ادارة المنتجات",
            router_name: ERoutesName.PRODUCTS,
          },
          {
            text: "قائمة المنتجات",
            router_name: ERoutesName.PRODUCTS_INDEX,
          },
        ],
      },
    },
    {
      path: "index/vendorProducts",
      name: ERoutesName.PRODUCTS_VENDOR_PRODUCTS,
      component: () => import("@/pages/products/screens/vendorProducts.vue"),
      meta: {
        allowedRoles: [
          RoleType.Manager,
          RoleType.Admin,
          RoleType.User,
          RoleType.Vendor,
        ],
        breadcrumb: [
          {
            text: "ادارة المنتجات",
            router_name: ERoutesName.PRODUCTS,
          },
          {
            text: "قائمة المنتجات",
            router_name: ERoutesName.PRODUCTS_INDEX,
          },
          {
            text: "قائمة منتجات المزودين",
            router_name: ERoutesName.PRODUCTS_VENDOR_PRODUCTS,
          },
        ],
      },
    },
    {
      path: "show/:id",
      name: ERoutesName.PRODUCTS_SHOW,
      component: () => import("@/pages/products/screens/show.vue"),
      meta: {
        allowedRoles: [
          RoleType.Manager,
          RoleType.Admin,
          RoleType.User,
          RoleType.Vendor,
        ],
        breadcrumb: [
          {
            text: "ادارة المنتجات",
            router_name: ERoutesName.PRODUCTS,
          },
          {
            text: "قائمة المنتجات",
            router_name: ERoutesName.PRODUCTS_INDEX,
          },
          {
            text: "عرض المنتج",
            router_name: ERoutesName.PRODUCTS_SHOW,
          },
        ],
      },
    },

    {
      path: "create",
      name: ERoutesName.PRODUCTS_CREATE,
      component: () => import("@/pages/products/screens/create.vue"),
      meta: {
        allowedRoles: [
          RoleType.Manager,
          RoleType.Admin,
          RoleType.User,
          RoleType.Vendor,
        ],
        breadcrumb: [
          {
            text: "ادارة المنتجات",
            router_name: ERoutesName.PRODUCTS,
          },
          {
            text: "قائمة المنتجات",
            router_name: ERoutesName.PRODUCTS_INDEX,
          },
          {
            text: "اضافة منتج جديد ",
            router_name: ERoutesName.PRODUCTS_CREATE,
          },
        ],
      },
    },
    {
      path: "import",
      name: ERoutesName.PRODUCTS_IMPORT,
      component: () => import("@/pages/products/screens/import.vue"),
      meta: {
        allowedRoles: [
          RoleType.Manager,
          RoleType.Admin,
          RoleType.User,
          RoleType.Vendor,
        ],
        breadcrumb: [
          {
            text: "ادارة المنتجات",
            router_name: ERoutesName.PRODUCTS,
          },
          {
            text: "قائمة المنتجات",
            router_name: ERoutesName.PRODUCTS_INDEX,
          },
          {
            text: "استيراد منتجات",
            router_name: ERoutesName.PRODUCTS_IMPORT,
          },
        ],
      },
    },
    {
      path: "edit/:id",
      name: ERoutesName.PRODUCTS_EDIT,
      component: () => import("@/pages/products/screens/update.vue"),
      meta: {
        allowedRoles: [
          RoleType.Manager,
          RoleType.Admin,
          RoleType.User,
          RoleType.Vendor,
        ],
        breadcrumb: [
          {
            text: "ادارة المنتجات",
            router_name: ERoutesName.PRODUCTS,
          },
          {
            text: "قائمة المنتجات",
            router_name: ERoutesName.PRODUCTS_INDEX,
          },
          {
            text: "تعديل منتج ",
            router_name: ERoutesName.PRODUCTS_EDIT,
          },
        ],
      },
    },
  ],
};

export default routes;
