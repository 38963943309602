import { ERoutesName } from "@/core/constant/ERoutesName";
import { RouterView } from "vue-router";
import { RoleType } from "../../core/constant/EUserRoles";

const routes = {
  path: "/settings/",
  component: RouterView,
  name: ERoutesName.SETTINGS,
  redirect: { name: ERoutesName.PROFILE },
  meta: {
    allowedRoles: [RoleType.Any],
    breadcrumb: [
      {
        text: "الاعدادات",
        router_name: ERoutesName.SETTINGS,
      },
    ],
  },
  children: [
    {
      path: "profile",
      name: ERoutesName.PROFILE,
      component: () => import("@/pages/settings/screens/ProfilePage.vue"),
      meta: {
        allowedRoles: [RoleType.Any],
        breadcrumb: [
          {
            text: "الاعدادات",
            router_name: ERoutesName.SETTINGS,
          },
          {
            text: "الملف الشخصي",
            router_name: ERoutesName.PROFILE,
          },
        ],
      },
    },
  ],
};

export default routes;
