import axios, { type AxiosInstance } from "axios";
import config from "@/core/config";
import AuthService from "../services/auth";
import { useToast } from "vue-toastification";

let api: AxiosInstance;

function _createHTTPClientInstance() {
  const toast = useToast();

  api = axios.create({
    baseURL: config.BASE_URL,
    // withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  api.interceptors.request.use((config) => {
    const isConnected = window.navigator.onLine;

    if (!isConnected) {
      toast.error("لا يوجد اتصال بالانترنت");
      return Promise.reject("لا يوجد اتصال بالانترنت");
    }

    return config;
  });

  api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        const authService = new AuthService();
        await authService.refreshToken(); // refresh token
        axios.request(error.config).catch(async (responseError) => {
          if (responseError?.response?.status === 401) {
            await authService.logOut();
            localStorage.removeItem("token");
            if (!location.href.includes("auth")) {
              location.href = "/auth/login";
            }
          }
        });
      }

      if (error.response?.status === 404) {
        toast.error("404 هذه الخدمة غير موجودة");
      }

      if (
        error.response?.data?.data ||
        error.response?.data?.message ||
        error.response?.data?.Data ||
        error.response?.data?.Message
      ) {
        toast.error(
          error.response?.data?.data ||
            error.response?.data?.message ||
            error.response?.data?.Data ||
            error.response?.data?.Message
        );
      }

      // trigger 'loading=false' event here
      return Promise.reject(error);
    }
  );

  return api;
}

export function useHttpClient() {
  if (!api) {
    _createHTTPClientInstance();
  }

  return api;
}
