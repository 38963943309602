import router from "@/router";
import { createPinia } from "pinia";
import Toast, { POSITION } from "vue-toastification";
import type { App } from "vue";

import "@/core/firebase";

// import "@/core/extensions";

// init css
import "vue-slider-component/theme/default.css";
import "vue-toastification/dist/index.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "@vueform/multiselect/themes/default.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "@/core/styles/index.scss";


import initGlobalComponents from "./components/index";

const toastOptions = {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 3000,
  rtl: true,
  hideProgressBar: true,
  closeOnClick: true,
  toastDefaults: {
    success: {
      toastClassName: "bg-success text-white text-sm",
    },
    error: {
      toastClassName: "bg-danger text-white text-sm",
    },
    warning: {
      toastClassName: "bg-warning text-white text-sm",
    },
  },
};

function initializeApp(app: App) {
  initGlobalComponents(app);

  app.use(Toast, toastOptions);
  app.use(createPinia());
  app.use(router);
  app.mount("#app");
}

export default initializeApp;
