import { ERoutesName } from "@/core/constant/ERoutesName";
import { RouteRecordRaw, RouterView } from "vue-router";
import { RoleType } from "../../core/constant/EUserRoles";

const routes: RouteRecordRaw = {
  path: "/splashScreen/",
  component: RouterView,
  name: ERoutesName.SPLASH_SCREEN,
  redirect: { name: ERoutesName.SPLASH_SCREEN_INDEX },
  meta: {
    allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
    breadcrumb: [
      {
        text: "ادارة شاشة البداية",
        router_name: ERoutesName.SPLASH_SCREEN,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.SPLASH_SCREEN_INDEX,
      component: () => import("@/pages/splashScreen/screens/index.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
        breadcrumb: [
          {
            text: "ادارة شاشة البداية",
            router_name: ERoutesName.SPLASH_SCREEN,
          },
          {
            text: "قائمة شاشة البداية",
            router_name: ERoutesName.SPLASH_SCREEN_INDEX,
          },
        ],
      },
    },
    {
      path: "create",
      name: ERoutesName.SPLASH_SCREEN_CREATE,
      component: () => import("@/pages/splashScreen/screens/create.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
        breadcrumb: [
          {
            text: "ادارة شاشة البداية ",
            router_name: ERoutesName.SPLASH_SCREEN,
          },
          {
            text: "قائمة شاشة البداية ",
            router_name: ERoutesName.SPLASH_SCREEN_INDEX,
          },
          {
            text: "اضافة شاشة البداية",
            router_name: ERoutesName.SPLASH_SCREEN_CREATE,
          },
        ],
      },
    },
    {
      path: "edit/:id",
      name: ERoutesName.SPLASH_SCREEN_EDIT,
      component: () => import("@/pages/splashScreen/screens/update.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
        breadcrumb: [
          {
            text: "ادارة شاشة البداية",
            router_name: ERoutesName.SPLASH_SCREEN,
          },
          {
            text: "قائمة شاشة البداية",
            router_name: ERoutesName.SPLASH_SCREEN_INDEX,
          },
          {
            text: "تعديل شاشة البداية",
            router_name: ERoutesName.SPLASH_SCREEN_EDIT,
          },
        ],
      },
    },
  ],
};

export default routes;
