import { useAuth } from "../controllers/auth";
import { RoleType } from "../constant/EUserRoles";

export default function () {
  const auth = useAuth(); // GET AUTH STORE: to get user role

  const has = (allowedRoles: RoleType[]) => {
    if (allowedRoles.includes(RoleType.Any)) return true;

    if (!auth.user) return false;

    if (!allowedRoles || allowedRoles.length === 0) return false;

    return allowedRoles.includes(auth.user.role);
  };

  return {
    has,
  };
}
