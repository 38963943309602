<template>
  <aside
    :class="is_active ? 'translate-x-0' : 'translate-x-full'"
    class="fixed z-20 flex flex-col h-screen overflow-auto transition-transform duration-150 ease-in transform bg-white shadow-xl ltr:left-0 rtl:right-0 text-primaryDark lg:static shadow-primary/10"
  >
    <div>
      <div
        class="flex items-center justify-between px-3 py-4 lg:justify-center"
      >
        <Image
          src="/logo.png"
          @click="
            $router.push({
              name: ERoutesName.MAIN,
            })
          "
          alt=""
          loading="lazy"
          class="h-12 cursor-pointer"
        />
        <button class="lg:hidden" @click="$emit('toggleSidebar')">
          <i class="text-3xl bx bx-menu text-primaryDark"></i>
        </button>
      </div>
    </div>
    <template v-for="(route, index) in menu" :key="index">
      <div v-if="route.children.length" class="w-full">
        <button
          @click="route.is_active = !route.is_active"
          :class="route.is_active ? ' border-r-4 border-primary' : ''"
          class="inline-flex flex-row items-center justify-between w-full gap-2 px-5 py-2 text-primaryDark"
        >
          <span class="flex items-center"
            ><i :class="route.icon" class="text-xl"></i>
            <span class="mx-1 text-base leading-10">{{
              route.title
            }}</span></span
          >
          <i
            class="transition-all duration-300 text-primaryDark bx bx-chevron-down"
            :class="{ 'transform rotate-180': route.is_active }"
          ></i>
        </button>
        <ul
          class="w-full pr-3 overflow-hidden transition-all duration-200"
          :class="{ 'max-h-0': !route.is_active, 'max-h-96': route.is_active }"
        >
          <li
            class="w-full px-2"
            v-for="child in route.children"
            :key="child.name"
          >
            <router-link
              :to="{
                name: child.name,
              }"
              class="flex flex-row items-center w-full gap-2 px-4 py-1 text-primaryDark"
              active-class="font-medium rounded-lg bg-gradient-to-l from-primary/30 to-secondary/30 text-primary"
            >
              <i :class="child.icon" class="text-xl"></i>
              <span class="mx-1 text-sm leading-10 !whitespace-nowrap">{{
                child.title
              }}</span>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="px-2" v-else>
        <router-link
          :to="{
            name: route.name,
          }"
          class="inline-flex flex-row items-center w-full gap-2 px-4 py-1 text-primaryDark"
          active-class="font-medium rounded-lg bg-gradient-to-l from-primary/30 to-secondary/30 text-primary"
        >
          <i :class="route.icon" class="text-xl"></i>
          <span class="mx-1 leading-10 text-md !whitespace-nowrap">{{
            route.title
          }}</span>
        </router-link>
      </div>
    </template>
  </aside>
</template>
<script lang="ts" setup>
import { navigationMenu } from "../constant/navigationMenu";
import { ref } from "vue";
import useHasRole from "../hooks/useHasRole";
import { RoleType } from "../constant/EUserRoles";
import { Route } from "lucide-vue-next";
import { ERoutesName } from "../constant/ERoutesName";

const { has } = useHasRole();

defineProps<{
  is_active: boolean;
}>();

defineEmits(["toggleSidebar"]);

const menu = ref(
  navigationMenu
    .filter((item) => item.allowedRoles && has(item.allowedRoles as RoleType[]))
    .map((item) => {
      if (item.children) {
        return {
          ...item,
          is_active: false,
          children: item.children.map((child) => {
            return {
              ...child,
              is_active: false,
            };
          }),
        };
      } else {
        return {
          ...item,
          is_active: false,
        };
      }
    })
);
</script>
