<template>
  <div>
    <div class="">
      <label :for="name" class="label">{{ label }}</label>
      <div class="flex items-center gap-3">
        <Avatar v-if="imgUrl || imgPath">
          <AvatarImage :src="imgUrl || imgPath || ''" alt="" />
        </Avatar>
        <label :for="name" class="w-auto input">
          <Field
            type="file"
            :name="name"
            :id="name"
            @change="setValue"
            class="hidden"
          />
          <span class="flex items-center gap-2 label">
            <i class="bx bx-upload"></i>
            <span class="block truncate">{{
              value
                ? value.name
                : imgPath
                ? imgPath.length > 60
                  ? imgPath.slice(0, 60) + "..."
                  : imgPath
                : "اختر ملف"
            }}</span>
          </span>
        </label>
      </div>

      <ErrorMessage :name="name" class="error-message" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { Field, ErrorMessage } from "vee-validate";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import { ref, toRef, watch } from "vue";

defineProps<{
  name: string;
  label: string;
  imgPath?: string;
}>();

const value = ref<File>();

const imgUrl = ref<string>("");

const emit = defineEmits<{ change: [value: File] }>();

function setValue(pickedValue: Event) {
  const file = (pickedValue.target as HTMLInputElement).files?.[0];
  if (!file) return;
  imgUrl.value = URL.createObjectURL(file);
  value.value = file;
}

watch(value, (val) => {
  if (val) emit("change", val);
});
</script>
