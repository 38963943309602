import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
} from "firebase/messaging";
import config from "@/core/config";
import { getAnalytics } from "firebase/analytics";
import { isNotificationSupported } from "./utils/functions";

const firebaseApp = initializeApp(config.firebaseConfig);
getAnalytics(firebaseApp);

let messaging: Messaging | null;

if (isNotificationSupported()) {
  messaging =
    Notification?.permission && Notification?.permission === "granted"
      ? getMessaging(firebaseApp)
      : null;

  if (Notification?.permission === "default") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        messaging = getMessaging(firebaseApp);
      }
    });
  }
}

export const getFCMToken = async () => {
  try {
    if (!messaging) return;
    if (!Notification?.permission) return;
    const token = await getToken(messaging, {
      vapidKey: config.FCM_vapidKey,
    });
    return token;
  } catch (e) {
    console.log(e);
    return "";
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (!messaging) return;
    onMessage(messaging, (payload) => {
      new Notification("إشعار جديد", {
        body: payload.notification?.body,
      });
      resolve(payload);
    });
  });
export default firebaseApp;
