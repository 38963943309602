export enum RoleType {
  Any = -1,
  None = 0,
  Manager = 1,
  Admin = 2,
  User = 3,
  Customer = 4,
  Vendor = 5,
  Delivery = 6,
}
