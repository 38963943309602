<template>
  <transition name="slide-fade">
    <div
      role="Container"
      id="Container"
      v-if="init"
      class="p-5 bg-white rounded-lg shadow-primary"
    >
      <template v-if="isLoading">
        <SkeltonLoading />
        <!-- <div class="flex items-center justify-center h-96">
          <Loading />
        </div> -->
      </template>

      <template v-else>
        <div class="h-full">
          <slot name="content"></slot>
        </div>
      </template>
    </div>
  </transition>
</template>
<script setup lang="ts">
import { onMounted } from "vue";
import { ref } from "vue";

const init = ref(false);

onMounted(() => {
  init.value = true;
});

defineProps<{
  isLoading?: boolean;
}>();
</script>
<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>