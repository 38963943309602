import { ERoutesName } from "@/core/constant/ERoutesName";
import { RouteRecordRaw, RouterView } from "vue-router";
import { RoleType } from '../../core/constant/EUserRoles';

const routes: RouteRecordRaw = {
  path: "/customer-support/",
  component: RouterView,
  name: ERoutesName.CUSTOMER_SUPPORT,
  redirect: { name: ERoutesName.CUSTOMER_SUPPORT_INDEX },
  meta: {
    allowedRoles: [RoleType.Manager, , RoleType.Admin],
    breadcrumb: [
      {
        text: "ادارة الدعم الفني",
        router_name: ERoutesName.CUSTOMER_SUPPORT,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.CUSTOMER_SUPPORT_INDEX,
      component: () => import("@/pages/customerSupport/screens/index.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة الدعم الفني",
            router_name: ERoutesName.CUSTOMER_SUPPORT,
          },
          {
            text: "قائمة موظفي الدعم الفني",
            router_name: ERoutesName.CUSTOMER_SUPPORT_INDEX,
          },
        ],
      },
    },
    {
      path: "show/:id",
      name: ERoutesName.CUSTOMER_SUPPORT_SHOW,
      component: () => import("@/pages/customerSupport/screens/show.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة الدعم الفني",
            router_name: ERoutesName.CUSTOMER_SUPPORT,
          },
          {
            text: "قائمة موظفي الدعم الفني",
            router_name: ERoutesName.CUSTOMER_SUPPORT_INDEX,
          },
          {
            text: "عرض مستخدم",
            router_name: ERoutesName.CUSTOMER_SUPPORT_SHOW,
          },
        ],
      },
    },
    {
      path: "create",
      name: ERoutesName.CUSTOMER_SUPPORT_CREATE,
      component: () => import("@/pages/customerSupport/screens/create.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة الدعم الفني",
            router_name: ERoutesName.CUSTOMER_SUPPORT,
          },
          {
            text: "قائمة موظفي الدعم الفني",
            router_name: ERoutesName.CUSTOMER_SUPPORT_INDEX,
          },
          {
            text: "اضافة مستخدم",
            router_name: ERoutesName.CUSTOMER_SUPPORT_CREATE,
          },
        ],
      },
    },
    {
      path: "edit/:id",
      name: ERoutesName.CUSTOMER_SUPPORT_EDIT,
      component: () => import("@/pages/customerSupport/screens/update.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة الدعم الفني",
            router_name: ERoutesName.CUSTOMER_SUPPORT,
          },
          {
            text: "قائمة موظفي الدعم الفني",
            router_name: ERoutesName.CUSTOMER_SUPPORT_INDEX,
          },
          {
            text: "تعديل مستخدم",
            router_name: ERoutesName.CUSTOMER_SUPPORT_EDIT,
          },
        ],
      },
    },
  ],
};

export default routes;
