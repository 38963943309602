import { ERoutesName } from "@/core/constant/ERoutesName";
import { RouteRecordRaw, RouterView } from "vue-router";
import { RoleType } from "../../core/constant/EUserRoles";

const routes: RouteRecordRaw = {
  path: "/sample-file/",
  component: RouterView,
  name: ERoutesName.SAMPLE_FILE,
  redirect: { name: ERoutesName.SAMPLE_FILE_INDEX },
  meta: {
    allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
    breadcrumb: [
      {
        text: "ادارة قالب الملفات",
        router_name: ERoutesName.SAMPLE_FILE,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.SAMPLE_FILE_INDEX,
      component: () => import("@/pages/adminFileSample/screens/index.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
        breadcrumb: [
          {
            text: "ادارة  قالب الملفات",
            router_name: ERoutesName.SAMPLE_FILE,
          },
          {
            text: "قائمة  قالب الملفات",
            router_name: ERoutesName.SAMPLE_FILE_INDEX,
          },
        ],
      },
    },
    {
      path: "create",
      name: ERoutesName.SAMPLE_FILE_CREATE,
      component: () => import("@/pages/adminFileSample/screens/create.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
        breadcrumb: [
          {
            text: "ادارة  قالب الملفات ",
            router_name: ERoutesName.SAMPLE_FILE,
          },
          {
            text: "قائمة  قالب الملفات ",
            router_name: ERoutesName.SAMPLE_FILE_INDEX,
          },
          {
            text: "اضافة قالب ",
            router_name: ERoutesName.SAMPLE_FILE_CREATE,
          },
        ],
      },
    },
    {
      path: "edit/:id",
      name: ERoutesName.SAMPLE_FILE_EDIT,
      component: () => import("@/pages/adminFileSample/screens/update.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, , RoleType.Admin, RoleType.User],
        breadcrumb: [
          {
            text: "ادارة  قالب الملفات",
            router_name: ERoutesName.SAMPLE_FILE,
          },
          {
            text: "قائمة  قالب الملفات",
            router_name: ERoutesName.SAMPLE_FILE_INDEX,
          },
          {
            text: "تعديل القالب ",
            router_name: ERoutesName.SAMPLE_FILE_EDIT,
          },
        ],
      },
    },
  ],
};

export default routes;
