import { RouteRecordRaw, RouterView } from "vue-router";
import { ERoutesName } from "../../core/constant/ERoutesName";

const routes: RouteRecordRaw[] = [
  {
    path: "/Forbidden",
    name: ERoutesName.FORBIDDEN,
    component: () => import("./Forbidden.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/NotFound",
    name: ERoutesName.NOT_FOUND,
    component: () => import("./NotFoundPage.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/ServerError",
    name: ERoutesName.SERVER_ERROR,
    component: () => import("./ServerError.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

export default routes;
