import { RouteRecordRaw, RouterView } from "vue-router";
import { ERoutesName } from "../../core/constant/ERoutesName";
import { RoleType } from "../../core/constant/EUserRoles";

const routes: RouteRecordRaw = {
  path: "/banner/",
  component: RouterView,
  name: ERoutesName.BANNER,
  redirect: { name: ERoutesName.BANNER_INDEX },
  meta: {
    allowedRoles: [RoleType.Manager, RoleType.Admin],
    breadcrumb: [
      {
        text: "بانر",
        router_name: ERoutesName.BANNER,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.BANNER_INDEX,
      component: () => import("@/pages/banner/screens/index.vue"),
      meta: {
        allowedRoles: [RoleType.Manager , RoleType.Admin],

        breadcrumb: [
          {
            text: "بانر",
            router_name: ERoutesName.BANNER,
          },
          {
            text: "قائمة البانر ",
            router_name: ERoutesName.BANNER_INDEX,
          },
        ],
      },
    },
  ],
};

export default routes;
