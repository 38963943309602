import { ERoutesName } from "@/core/constant/ERoutesName";
import { RoleType } from "@/core/constant/EUserRoles";
import { RouteRecordRaw, RouterView } from "vue-router";

const routes: RouteRecordRaw = {
  path: "/vndors/",
  component: RouterView,
  name: ERoutesName.VENDORS,
  redirect: { name: ERoutesName.VENDORS_INDEX },
  meta: {
    allowedRoles: [RoleType.Manager, RoleType.Admin],
    breadcrumb: [
      {
        text: "ادارة البائعين",
        router_name: ERoutesName.VENDORS,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.VENDORS_INDEX,
      component: () => import("@/pages/vendors/screens/index.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة البائعين",
            router_name: ERoutesName.VENDORS,
          },
          {
            text: "قائمة البائعين",
            router_name: ERoutesName.VENDORS_INDEX,
          },
        ],
      },
    },
    {
      path: "show/:id",
      name: ERoutesName.VENDORS_SHOW,
      component: () => import("@/pages/vendors/screens/show.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة البائعين",
            router_name: ERoutesName.VENDORS,
          },
          {
            text: "قائمة البائعين",
            router_name: ERoutesName.VENDORS_INDEX,
          },
          {
            text: "عرض البائع",
            router_name: ERoutesName.VENDORS_SHOW,
          },
        ],
      },
    },
    {
      path: "create",
      name: ERoutesName.VENDORS_CREATE,
      component: () => import("@/pages/vendors/screens/create.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة البائعين",
            router_name: ERoutesName.VENDORS,
          },
          {
            text: "قائمة البائعين",
            router_name: ERoutesName.VENDORS_INDEX,
          },
          {
            text: "اضافة بائع",
            router_name: ERoutesName.VENDORS_CREATE,
          },
        ],
      },
    },
    {
      path: "edit/:id",
      name: ERoutesName.VENDORS_EDIT,
      component: () => import("@/pages/vendors/screens/update.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة البائعين",
            router_name: ERoutesName.VENDORS,
          },
          {
            text: "قائمة البائعين",
            router_name: ERoutesName.VENDORS_INDEX,
          },
          {
            text: "تعديل بائع",
            router_name: ERoutesName.VENDORS_EDIT,
          },
        ],
      },
    },
  ],
};

export default routes;
