import { RouteRecordRaw, RouterView } from "vue-router";
import { ERoutesName } from '../../core/constant/ERoutesName';
import { RoleType } from '../../core/constant/EUserRoles';

const routes: RouteRecordRaw = {
  path: "/application-options/",
  component: RouterView,
  name: ERoutesName.APPLICATION_OPTION,
  redirect: { name: ERoutesName.APPLICATION_OPTION_INDEX },
  meta: {
    allowedRoles: [RoleType.Manager, RoleType.Admin],
    breadcrumb: [
      {
        text: "ادارة خيارات التطبيق",
        router_name: ERoutesName.APPLICATION_OPTION,
      },
    ],
  },
  children: [
    {
      path: "index",
      name: ERoutesName.APPLICATION_OPTION_INDEX,
      component: () => import("@/pages/applicationOptions/screens/index.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة خيارات التطبيق",
            router_name: ERoutesName.APPLICATION_OPTION,
          },
          {
            text: "قائمة خيارات التطبيق",
            router_name: ERoutesName.APPLICATION_OPTION_INDEX,
          },
        ],
      },
    },
    
    {
      path: "create",
      name: ERoutesName.APPLICATION_OPTION_CREATE,
      component: () => import("@/pages/applicationOptions/screens/create.vue"),
      meta: {
        allowedRoles: [RoleType.Manager, RoleType.Admin],
        breadcrumb: [
          {
            text: "ادارة خيارات التطبيق ",
            router_name: ERoutesName.APPLICATION_OPTION,
          },
          {
            text: "قائمة خيارات التطبيق ",
            router_name: ERoutesName.APPLICATION_OPTION_INDEX,
          },
          {
            text: "اضافة خيارات التطبيق",
            router_name: ERoutesName.APPLICATION_OPTION_CREATE,
          },
        ],
      },
    },
   
  ],
};

export default routes;
