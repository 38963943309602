import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

import Layout from "@/core/layout/Layout.vue";
import authRoute from "@/pages/auth/routes";
import userRoutes from "@/pages/users/routes";
import settingsRoutes from "@/pages/settings/routes";
import categoriesRoutes from "@/pages/categories/routes";
import citiesRoutes from "@/pages/cities/routes";
import subCategoryRoutes from "@/pages/subCategory/routes";
import customerSupportRoutes from "@/pages/customerSupport/routes";
import brandsRoutes from "@/pages/brands/routes";
import termsRoutes from "@/pages/terms/routes";
import carMakersRoutes from "@/pages/carMaker/routes";
import carModalRoutes from "@/pages/carModal/routes";
import carSubModalRoutes from "@/pages/carSubModal/routes";
import productTaxRoutes from "@/pages/productTax/routes";
import productUnitRoutes from "@/pages/productUnit/routes";
import vendorsRoutes from "@/pages/vendors/routes";
import productsRoutes from "@/pages/products/routes";
import customersRoutes from "@/pages/customers/routes";
import applicationOptionsRoutes from "@/pages/applicationOptions/routes";
import bannerRoures from "@/pages/banner/routes";
import deliveryOptionsRoutes from "@/pages/deliveryOptions/routes";
import ordersRoutes from "@/pages/orders/routes";
import filtetToolRoutes from "@/pages/filterTool/routes";
import couponsRoutes from "@/pages/coupons/routes";
import walletRoutes from "@/pages/wallet/routes";
import bonusesRoures from "@/pages/bonuses/routes";
import paymentRoutes from "@/pages/payment/routes";
import splashScreenRoutes from "@/pages/splashScreen/routes";
import adminFileSampleRoutes from "@/pages/adminFileSample/routes";

import errors from "@/pages/errors/routes";
import { ERoutesName } from "./core/constant/ERoutesName";
import { navigationGuards } from "./core/middleware/navigationGuards";
import { RoleType } from "./core/constant/EUserRoles";

export const dashboardRoutes: RouteRecordRaw = {
  path: "/",
  component: Layout,
  name: ERoutesName.MAIN,
  redirect: { name: ERoutesName.DASHBOARD },
  children: [
    {
      path: "/dashboard/",
      component: () => import("@/pages/dashboard/index.vue"),
      name: ERoutesName.DASHBOARD,
      meta: {
        allowedRoles: [RoleType.Any],
        breadcrumb: [
          {
            text: "الرئيسية",
            router_name: ERoutesName.MAIN,
          },
          {
            text: "لوحة التحكم",
            router_name: ERoutesName.DASHBOARD,
          },
        ],
      },
    },
    userRoutes,
    settingsRoutes,
    citiesRoutes,
    customerSupportRoutes,
    categoriesRoutes,
    subCategoryRoutes,
    brandsRoutes,
    termsRoutes,
    carMakersRoutes,
    carModalRoutes,
    carSubModalRoutes,
    productTaxRoutes,
    productUnitRoutes,
    vendorsRoutes,
    productsRoutes,
    customersRoutes,
    applicationOptionsRoutes,
    bannerRoures,
    deliveryOptionsRoutes,
    ordersRoutes,
    filtetToolRoutes,
    couponsRoutes,
    walletRoutes,
    bonusesRoures,
    paymentRoutes,
    splashScreenRoutes,
    adminFileSampleRoutes,
  ],
  meta: {
    allowedRoles: [RoleType.Any],
    breadcrumb: [
      {
        text: "الرئيسية",
        router_name: ERoutesName.MAIN,
      },
    ],
  },
};

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [authRoute, dashboardRoutes, ...errors],
});

router.beforeEach(async (to, from, next) => navigationGuards(to, from, next));

export default router;
