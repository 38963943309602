<template>
  <div>
    <label :for="name" class="flex items-center gap-1">
      <span v-if="required" class="text-2xl font-bold text-red-600">*</span>
      <span>
        {{ label }}
      </span>
    </label>
    <Multiselect
      :name="name"
      :id="name"
      v-model="modelValue"
      :options="options"
      track-by="label"
      valueProp="value"
      label="label"
      :multiple="true"
      class="input"
      :object="object"
      :searchable="true"
      :rtl="true"
      :mode="'tags'"
    />
    <ErrorMessage :name="name" class="error-message" />
  </div>
</template>

<script lang="ts" setup>
import Multiselect from "@vueform/multiselect";
import { ErrorMessage } from "vee-validate";

const modelValue = defineModel<{ label: string; value: string }[]>();

defineProps<{
  label: string;
  name: string;
  required?: boolean;
  object?: boolean;
  options: { label: string; value: string }[];
}>();
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
